import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title, Copy } from './item.css';
import styled from 'styled-components';

const ListItem = styled.li`
  list-style: circle;
  margin-bottom: 8px;
`;

const Item = ({ title, copy, image }) => {
  let CopyText = <Copy>{copy}</Copy>;
  // split by comma if string contains a special break character |
  if (copy.includes('|')) {
    const delimCopy = copy.split('|');
    CopyText = (
      <Copy>
        <ul>
          {delimCopy.map(e => {
            return <ListItem key={e}>{e}</ListItem>;
          })}
        </ul>
      </Copy>
    );
  }
  console.log(CopyText);

  return (
    <figure>
      <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
      <figcaption>
        <Title>{title}</Title>
        {CopyText}
      </figcaption>
    </figure>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item;
