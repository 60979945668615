import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem;
  margin-left: 0;
`;

export const Copy = styled.div`
  color: #757575;
  margin: 2rem;
  margin-left: 0;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;
